import axios from 'axios'
export const useApiClient = (endpoint: string) => {

    let config = useRuntimeConfig();
    const axiosInstance = axios.create({
        baseURL: (process.env.NODE_ENV == 'development' ? config.public.appApiDev : config.public.appApi) + '/api'
    })

    return {
        get: async () => {
            const { data } = await axiosInstance.get(endpoint)
            return data
        },

        post: async (body: any) => {
            const { data } = await axiosInstance.post(endpoint, body)
            return data
        },

        delete: async (body: any | undefined = null) => {
            const { data } = await axiosInstance.delete(endpoint, body)
            return data
        }
    }
}